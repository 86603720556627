<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Calendar',
  data() {
    return {}
  }
}
</script>

<style>
.theme--light.v-sheet {
  background-color: transparent;
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day,
.theme--light.v-sheet,
.text-black {
  color: #111517 !important;
}
</style>
